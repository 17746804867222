<template>
  <div class="largesize">
    <!-- 头 -->
    <div class="careld">
      <img class="logoimg" src="../assets/logo1.png" alt />
      <span class="span1">康康智慧养老服务平台数据大屏</span>
    </div>
    <!-- 数据 -->
    <div class="number">
      <!-- 用户数 -->
      <div class="usernum">
        <div class="yiuser">
          <span>用户数</span>
        </div>
        <div class="eruser">
          <span>家庭</span>
          <span>3750</span>
        </div>
        <div class="sanuser">
          <span>老人</span>
          <span>5987</span>
        </div>
        <div class="siuser">
          <span>子女</span>
          <span>4452</span>
        </div>
      </div>
      <!-- 求助数 -->
      <div class="seekhelp">
        <span>求助数</span>
        <span>256489</span>
      </div>
      <!-- 关爱数 -->
      <div class="seekhelp">
        <span>关爱数</span>
        <span>1589954</span>
      </div>
      <!-- 服务数 -->
      <div class="seekhelp">
        <span>服务数</span>
        <span>336598</span>
      </div>
    </div>
    <!-- 比较数据 -->
    <div class="regionnum">
      <!-- 同比 -->
      <div class="yesron">
        <div class="tong">
          <span>同比</span>
        </div>
        <div class="namenum">
          <span>项目</span>
          <span>比上日</span>
          <span>比上月</span>
        </div>
        <div class="yesrondiv">
          <span>家庭数</span>
          <span>
            24
            <img src="../assets/rise.png" alt />
          </span>
          <span>
            125
            <img src="../assets/rise.png" alt />
          </span>
        </div>
        <div class="yesrondiv">
          <span>求助数</span>
          <span>
            24
            <img src="../assets/rise.png" alt />
          </span>
          <span>
            125
            <img src="../assets/rise.png" alt />
          </span>
        </div>
        <div class="yesrondiv">
          <span>关爱数</span>
          <span>
            24
            <img src="../assets/decline.png" alt />
          </span>
          <span>
            125
            <img src="../assets/rise.png" alt />
          </span>
        </div>
        <div class="yesrondiv">
          <span>服务数</span>
          <span>
            24
            <img src="../assets/rise.png" alt />
          </span>
          <span>
            125
            <img src="../assets/rise.png" alt />
          </span>
        </div>
        <div class="yesrondiv">
          <span>支付额</span>
          <span>-</span>
          <span>-</span>
        </div>
      </div>
      <!-- 地图 -->
      <div class="map">
        <img class="img1" src="../assets/location.png" alt="">
        <img class="img2" src="../assets/location.png" alt="">
        <img class="img3" src="../assets/location.png" alt="">
        <img class="img4" src="../assets/location.png" alt="">
        <img class="img5" src="../assets/location.png" alt="">
        <img class="img6" src="../assets/location.png" alt="">
      </div>
      <!-- 实时趋势 -->
      <div class="timetrend">
        <div class="trend">
          <div class="dazi">实时趋势</div>
          <div class="main">
            <div class="divspan">
              电话
              <span class="divi tell"></span>
            </div>
            <div class="divspan">
              视频
              <span class="divi video"></span>
            </div>
            <div class="divspan">
              呼叫服务
              <span class="divi service"></span>
            </div>
            <div class="divspan">
              主动关怀
              <span class="divi care"></span>
            </div>
          </div>
          <div id="main3" style=" width:auto;height:160px;"></div>
        </div>
        <div class="earlywarning">
          <div class="dazi">预警</div>
          <div class="mains">
            <div class="yimain">
              <span>当前预警</span>
              <span>259</span>
            </div>
            <div class="ermain">
              <span>SOS预警总数</span>
              <span>1526</span>
            </div>
            <div class="sanmain">
              <span>排除误报</span>
              <span>368</span>
            </div>
            <div class="simain">
              <span>求助数</span>
              <span>4558</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分布 -->
    <div class="distribution">
      <div class="seek">
        <div class="spandiv">求助分布</div>
        <div id="main" style=" width:auto;height:130px;"></div>
      </div>
      <div class="services">
        <div class="spandiv">服务分布</div>
        <div id="main1" style=" width:auto;height:130px;"></div>
      </div>
      <div class="cares">
        <div class="spandiv">关怀分布</div>
        <div id="main2" style=" width:auto;height:130px;"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.drawChart();
    this.drawChart1();
    this.drawChart2();
    this.drawChart3();
  },
  methods: {
    // 求助分布
    drawChart() {
      //基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("main"));
      // 绘制图表
      myChart.setOption({
        tooltip: {},
        grid: { x: 0, y: 0, x2: 0, y2: 40 },
        xAxis: {
          data: [
            "电话",
            "视频",
            "照片",
            "健康",
            "顾问",
            "收音机",
            "留言",
            "提醒"
          ],
          // 单独隐藏坐标线：
          axisLine: {
            show: false
          },
          // 单独隐藏刻度线
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 0,
            show: true,
            textStyle: {
              color: "#bbbbbb", //更改坐标轴文字颜色
              fontSize: 18, //更改坐标轴文字大小
              padding: 10
            }
          }
        },
        yAxis: {
          show: false
        },
        series: [
          {
            name: "销量",
            type: "bar",
            itemStyle: {
              normal: {
                //好，这里就是重头戏了，定义一个list，然后根据所以取得不同的值，这样就实现了，
                color: function(params) {
                  // build a color map as your need.
                  var colorList = [
                    "#ffaaa7",
                    "#ffd3b4",
                    "#d5ecc2",
                    "#98ddca",
                    "#ffaaa7",
                    "#ffd3b4",
                    "#d5ecc2",
                    "#98ddca"
                  ];
                  return colorList[params.dataIndex];
                }
              }
            }, //设置柱的宽度，要是数据太少，柱子太宽不美观~
            barWidth: 20,
            data: [17, 20, 36, 10, 10, 20, 8, 15]
            // barCategoryGap:'20%',/*多个并排柱子设置柱子之间的间距*/
          }
        ]
      });
    },
    // 服务分布
    drawChart1() {
      //基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("main1"));
      // 绘制图表
      myChart.setOption({
        tooltip: {},
        grid: { x: 0, y: 0, x2: 0, y2: 40 },
        xAxis: {
          data: ["助行", "助餐", "助浴", "家政", "维修", "配送", "心理"],
          // 单独隐藏坐标线：
          axisLine: {
            show: false
          },
          // 单独隐藏刻度线
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 0,
            show: true,
            textStyle: {
              color: "#bbbbbb", //更改坐标轴文字颜色
              fontSize: 18, //更改坐标轴文字大小
              padding: 10
            }
          }
        },
        yAxis: {
          show: false
        },
        series: [
          {
            name: "销量",
            type: "bar",
            itemStyle: {
              normal: {
                //好，这里就是重头戏了，定义一个list，然后根据所以取得不同的值，这样就实现了，
                color: function(params) {
                  // build a color map as your need.
                  var colorList = [
                    "#ffaaa7",
                    "#ffd3b4",
                    "#d5ecc2",
                    "#98ddca",
                    "#ffaaa7",
                    "#ffd3b4",
                    "#d5ecc2",
                    "#98ddca"
                  ];
                  return colorList[params.dataIndex];
                }
              }
            }, //设置柱的宽度，要是数据太少，柱子太宽不美观~
            barWidth: 20,
            data: [36, 20, 26, 18, 11, 20, 12, 15]
            // barCategoryGap:'20%',/*多个并排柱子设置柱子之间的间距*/
          }
        ]
      });
    },
    // 关怀分布
    drawChart2() {
      //基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("main2"));
      // 绘制图表
      myChart.setOption({
        tooltip: {},
        grid: { x: 0, y: 0, x2: 0, y2: 40 },
        xAxis: {
          data: ["问候", "天气", "提醒", "心理", "周报", "月报"],
          // 单独隐藏坐标线：
          axisLine: {
            show: false
          },
          // 单独隐藏刻度线
          axisTick: {
            show: false
          },
          axisLabel: {
            interval: 0,
            show: true,
            textStyle: {
              color: "#bbbbbb", //更改坐标轴文字颜色
              fontSize: 18, //更改坐标轴文字大小
              padding: 10
            }
          }
        },
        yAxis: {
          show: false
        },
        series: [
          {
            name: "销量",
            type: "bar",
            itemStyle: {
              normal: {
                //好，这里就是重头戏了，定义一个list，然后根据所以取得不同的值，这样就实现了，
                color: function(params) {
                  // build a color map as your need.
                  var colorList = [
                    "#ffaaa7",
                    "#ffd3b4",
                    "#d5ecc2",
                    "#98ddca",
                    "#ffaaa7",
                    "#ffd3b4",
                    "#d5ecc2",
                    "#98ddca"
                  ];
                  return colorList[params.dataIndex];
                }
              }
            }, //设置柱的宽度，要是数据太少，柱子太宽不美观~
            barWidth: 20,
            data: [28, 10, 20, 22, 13, 25, 12, 20]
            // barCategoryGap:'20%',/*多个并排柱子设置柱子之间的间距*/
          }
        ]
      });
    },
    // 关怀分布
    drawChart3() {
      //基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("main3"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
            trigger: 'axis'
        },
        grid: { x: 110, y: 26, x2: 29, y2: 5 },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          interval: 0,
          data: [],
          // 单独隐藏刻度线
          axisTick: {
            show: false
          },

        },
        yAxis: {
          axisLine: {
            show: true
          },
          type: 'value',
          axisLabel: {
            interval: 0,
            show: true,
            textStyle: {
              color: "#a9a9a9", //更改坐标轴文字颜色
              fontSize: 18, //更改坐标轴文字大小
              padding: 10
            }
          }
        },
        series: [
          {  
              name:'电话',  
              type:'line',  
              stack: '电话',  
              itemStyle : {  
                  normal : { 
                    color:'#ffa9a7' ,
                      // lineStyle:{  
                      //     color:'#ffa9a7'  
                      // }  
                  }  
              },  
              data:[220, 369, 235, 220, 220, 158, 220]  
          }  ,
          {  
              name:'视频',  
              type:'line',  
              stack: '电话',  
              itemStyle : {  
                  normal : { 
                    color:'#ffd3b4' ,
                  }  
              },  
              data:[364, 256, 536, 314, 298, 695, 654]  
          }  ,
          {  
              name:'呼叫服务',  
              type:'line',  
              stack: '呼叫服务',  
              itemStyle : {  
                  normal : { 
                    color:'#d5ecc2' ,
                  }  
              },  
              data:[852, 459, 365, 278, 158, 751, 510]  
          }  ,
          {  
              name:'主动关怀',  
              type:'line',  
              stack: '主动关怀',  
              itemStyle : {  
                  normal : { 
                    color:'#98ddca' ,
                  }  
              },  
              data:[150, 258, 448, 369, 654, 159, 458]  
          }  ,
        ]
      });
    }
  }
};
</script>

<style lang="less" scoped>
.largesize {
  background: #eaeaea;
  font-family: Medium;
  width: 1750px;
  padding: 0 80px;
  // 头
  .careld {
    display: flex;
    align-items: center;
    height: 145px;
    justify-content: center;
    .logoimg {
      width: 230px;
      height: 41px;
      margin-right: 20px;
    }
    .span1 {
      font-size: 42px;
      font-family: Family;
      font-weight: bolder;
      color: #92aba7;
    }
  }
  // 数据
  .number {
    display: flex;
    justify-content: space-around;
    //   用户数
    .usernum {
      background-image: url("../assets/usernum.png");
      background-repeat: no-repeat;
      width: 845px;
      height: 126px;
      display: flex;
      .yiuser {
        padding: 0 20px;
        padding-top: 5px;
        flex: 1;
        span {
          color: #ffffff;
          font-size: 30px;
        }
      }
      .eruser,
      .sanuser,
      .siuser {
        flex: 1;
        padding: 0 20px;
        padding-top: 10px;
        span:first-child {
          display: block;
          font-size: 26px;
          color: #f5d4cd;
        }
        span:nth-child(2) {
          color: #ffffff;
          font-size: 39px;
          font-weight: bolder;
        }
      }
    }
    // 求助数 关爱数  服务数
    .seekhelp {
      background-image: url("../assets/service.png");
      background-repeat: no-repeat;

      width: 270px;
      height: 120px;
      margin-left: 20px;
      span:first-child {
        display: block;
        color: #ffffff;
        font-size: 30px;
        margin-left: 30px;
        margin-top: 5px;
      }
      span:last-child {
        color: #ffffff;
        font-size: 39px;
        font-weight: bolder;
        float: right;
        margin-right: 30px;
      }
    }
  }
  // 统计
  .regionnum {
    display: flex;
    margin-top: 33px;
    height: 496px;
    //   同比
    .yesron {
      width: 428px;
      background: #ffffff;
      border-radius: 10px;
      .tong {
        font-size: 26px;
        margin-top: 28px;
        margin-bottom: 40px;
        color: #d78673;
        padding-left: 35px;
        padding-right: 39px;
      }
      .namenum {
        color: #8eada7;
        line-height: 60px;
        font-size: 22px;
        padding-left: 35px;
        padding-right: 39px;
        display: flex;
        span:nth-child(2){
          margin-left: 10px;
        }
        span:last-child{
         margin-left: 10px;
        }
        span {
          flex: 1;
        }
      }
      .yesrondiv {
        color: #a9a9a9;
        line-height: 60px;
        font-size: 22px;
        padding-left: 35px;
        padding-right: 39px;
        display: flex;
        span:nth-child(2){
          text-align: center;
        }
        span:last-child{
          text-align: center;
        }
        span {
          
          flex: 1;
          img{
            margin-left: 23px;
          }
        }
      }
    }
    //   地图
    .map {
      flex: 1;
      background: url("../assets/map.png") no-repeat center;
      position: relative;
      img{
        width: 24px;
        height: 29px;
      }
      .img1{
        position: absolute;
        left: 580px;
        top: 83px;
      }
      .img2{
        position: absolute;
        left: 511px;
        top: 180px;
      }
      .img3{
        position: absolute;
        left: 435px;
        top: 245px;
      }
      .img4{
        position: absolute;
        left: 550px;
        top: 280px;
      }
      .img5{
        position: absolute;
        left: 479px;
        top: 318px;
      }
      .img6{
        position: absolute;
        left: 368px;
        top: 359px;
      }
    }
    //   实时趋势
    .timetrend {
      width: 559px;
      .dazi {
        font-size: 28px;
        color: #d68572;
        margin-left: 34px;
        padding-top: 24px;
      }
      .trend {
        height: 291px;
        background: #ffffff;
        border-radius: 10px;
        margin-bottom: 14px;
        .main {
          margin-left: 34px;
          margin-right: 34px;
          display: flex;
          justify-content: space-between;
          .divspan {
            display: flex;
            font-size: 19px;
            color: #93aca8;
            margin-top: 5px;
            align-items: center;
            .divi {
              display: block;
              width: 22px;
              height: 22px;
              margin-left: 10px;
            }
            .tell {
              background: #ffaaa7;
            }
            .video {
              background: #ffd3b4;
            }
            .service {
              background: #d5ecc2;
            }
            .care {
              background: #98ddca;
            }
          }
        }
      }
      .earlywarning {
        height: 190px;
        background: #ffffff;
        border-radius: 10px;
        .mains {
          display: flex;
          margin-left: 33px;
          margin-right: 40px;
          justify-content: space-between;
          .yimain {
            span:last-child {
              font-weight: bolder;
              color: #d78673 !important;
            }
          }
          .yimain,
          .ermain,
          .sanmain,
          .simain {
            display: flex;
            flex-direction: column;
            span:first-child {
              font-size: 19px;
              color: #93aca8;
              margin-top: 5px;
            }
            span:last-child {
              font-size: 29px;
              color: #a8a8a8;
              margin-top: 6px;
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }
  // 分布
  .distribution {
    height: 217px;
    background: #ffffff;
    border-radius: 10px;
    margin-top: 14px;
    display: flex;
    padding-left: 34px;
    padding-right: 30px;
    // justify-content: space-between;
    .seek,
    .services {
      margin-right: 170px;
    }
    .seek,
    .services,
    .cares {
      flex: 1;
      font-size: 26px;
      color: #dc917e;
      margin-top: 28px;
    }
  }
}
</style>